import React , {useState,  useEffect} from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Select from 'react-select'

export const Header = ( ) => {
    const options = [
      { value: 'en', label: 'English' },
        { value: 'vi', label: 'Việt Nam' },
       ]
    const findCurrentOption = (lang: string) => {
        return options.find(option => option.value === lang) || options[0]; // Fallback to first option
      };
    const {language ,  changeLanguage} = useI18next();
    const [selectedOption, setSelectedOption] = useState(findCurrentOption(language));

  
      
       useEffect(() => {
        // Update selected option if the language changes elsewhere in the app
        setSelectedOption(findCurrentOption(language));
      }, [language]);
  const handleLanguageChange = (optionValue:any) => {
    setSelectedOption(optionValue); // Update the selected option
    const selectedLanguage = optionValue.value;
    changeLanguage(selectedLanguage);
 
  };
    return (
         <div style={{margin: 0 , width:"150px" , "height":"40px"}}>
        <Select 
        className="text-[#000000]"
         options={options}
         defaultValue={options[0]}
         value={selectedOption}
         onChange={(optionValue) => handleLanguageChange(optionValue)}
        />
        </div>
         
      
    );
};
 
 