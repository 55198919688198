import "../css/lib.css";
import "../css/main.css";
import "../css/normalize.css";
import "../style.css";

import { Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import { navigate } from "gatsby";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { ExpiredLink } from "../components/expired";
import {
  HeaderComponent,
  LoadingComponent,
  NotFoundComponent,
  // PhoneCodeInputComponent,
  QuestionV2Component,
  RatingV2Component,
  QuestionV3Component,
  RatingV3Component,
  
} from "../components/index";
import { ThankYouComponent } from "../components/thank-you";
import {
  questionsDataOffline,
  questionsDataOnlineAndCollectInShop,
  questionsDataOnlineAndDeliveryAtHome,
  questionsDataWarrantyProduct,
  testEng
} from "../data";
import { FeedbackRepository } from "../repositories/feedback_repository";
import { useStyles } from "../style";
import { Question } from "../types";
import { ThankYouComponentV3 } from "../components/thank-you-v3";
import {Header} from "../components/language/header"

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface Properties {
  soNum: string;
  phoneNum: string;
  company: string;
}
declare global {
  interface Window {
    MSStream?: any; // Extend the window interface for MSStream
    opera?: any;    // Extend the window interface for opera
  }
}
// markup
const IndexPage: React.FC<Properties> = ({ soNum, phoneNum, company }: any) => {
  const classes = useStyles();
  // const { soNum, phoneNum } = queryString.parse(location.search);

  const [soNumState, _] = useState<string>(soNum?.toString() || ``);
  const [phoneNumState, setPhoneNumState] = useState<string>(phoneNum?.toString() || ``);
  const [rating, setRating] = useState<number>(-1);
  const [questionType, setQuestionType] = useState<number>(0);
   const [isValidate, setIsValidate] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRated, setIsRated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [questionsDataObject, setQuestionsData] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);

  const [isExpired, setIsExpired] = useState<boolean>(false);
  const showErrorMsg = async (msg: string): Promise<void> => {
    setErrorMessage(undefined);
    await sleep(200);
    setErrorMessage(msg);
    await sleep(5000);
    setErrorMessage(undefined);
  };
  const handleReFeedBack = () => {
    setIsSave(false);
    setIsExpired(false);
    setIsValidate(true);
  };
  const initValidate = async (code: string): Promise<boolean> => {
    let _isValidate = false;
    if (!code && !phoneNumState) return _isValidate;
    setIsLoading(true);
    const validateData = await FeedbackRepository.validate(soNumState, code || phoneNumState);
    console.log(`setIsSave`, validateData);
    setQuestionType(Number(validateData?.data?.feedback_object_type));

    if (validateData && validateData.data?.valid && validateData.statusCode === 200) {
       setIsValidate(true);
      if (Number(validateData?.data?.feedback_object_type) === 1) {
         setQuestionsData(questionsDataOnlineAndCollectInShop);

        
      }
      if (Number(validateData?.data?.feedback_object_type) === 2) {
        setQuestionsData(questionsDataOnlineAndCollectInShop);
      }
      if (Number(validateData?.data?.feedback_object_type) === 3) {
        setQuestionsData(questionsDataOnlineAndDeliveryAtHome);
      }
      if (Number(validateData?.data?.feedback_object_type) === 5) {
        setQuestionsData(questionsDataWarrantyProduct);
      }
      _isValidate = true;
    }
    if (validateData && validateData.statusCode === 409) {
      setIsSave(true);
      setRating(get(validateData, `data.rating`));
      _isValidate = true;
    }
    if (validateData && validateData.statusCode === 419) {
      setIsExpired(!isExpired);
    }
    setIsLoading(false);
    return _isValidate;
  };

  const saveFeedback = async (feedbacks: any): Promise<boolean> => {
    let _isSave = false;
    setIsLoading(true);
    const saveData = await FeedbackRepository.save(soNumState, phoneNum, feedbacks ?? {}, rating);
    await new Promise((r) => setTimeout(r, 1000));
    console.log(`setIsSave`, saveData);
    if (saveData && saveData.success === true && saveData.statusCode === 200) {
      console.log(`setIsSave`, setIsSave);
      setIsSave(true);
      _isSave = true;
    }
    setIsLoading(false);
    // const checkType = questionType == 1 || questionType == 2 || questionType == 3;
    // if (rating >= 4 && checkType) {
    //   navigate(`/optional-questions?soNum=${soNum}&phoneNum=${phoneNum}&company=${company ?? `pnj`}&orderType=${questionType}`);
    // }
    return _isSave;
  };
  const submitPhoneNumber = async (code: string): Promise<void> => {
    console.log(code);
    if (code.length === 4) {
      setPhoneNumState(code);
      await sleep(200);
      if (!(await initValidate(code))) showErrorMsg(`4 số cuối SĐT không hợp lệ xin hãy thử lại`);
    }
  };

  const submitQuestion = (data: { [key: string]: string }): void => {
    if (rating <= 0) {
      showErrorMsg(`Xin hãy chọn đánh giá trải nghiệm!`);
      return;
    }
    console.log(`submitQuestion: `, data);
    saveFeedback(data);
  };

  const selectRating = async (value: number): Promise<void> => {

    setRating(value);
    const questionObjectType = questionsDataObject.filter((quenstion:any) => {
      return   Number(quenstion.group) == value; 
    })
    if(questionObjectType.length > 0 ) {

    const data = questionObjectType[0].questions
       setQuestions([...data])
  };
}
   useEffect(() => {
    initValidate(``).then(() => setInitLoading(false));
  }, []);
  // const isWebView = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  //   // iOS WebView detection
  //   const isIOSWebView = /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);
  
  //   // Android WebView detection
  //   const isAndroidWebView =
  //   /Android/.test(userAgent) &&
  //   (/wv/.test(userAgent) || /Version\/[0-9.]+/.test(userAgent));  
  //   return isIOSWebView || isAndroidWebView;
  // };
  
  // const redirectToDeviceBrowser = async () => {
  //    if (!isWebView()) {
  //     console.log("Not in a WebView, skipping redirection.");
  //     return;
  //   }
  //   const url = `${location.origin || window.location.origin}${location.pathname}${location.search}${location.hash}`;
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   // iOS detection
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     const iosUrl = `x-safari-${url}`;
  //     window.location.href = iosUrl;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const iosOldUrl = `com-apple-mobilesafari-tab:${url}`;
  //     window.location.href = iosOldUrl;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const chromeUrl = `googlechrome://${url.replace("https://", "").replace("http://", "")}`;
  //     window.location.href = chromeUrl;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const firefoxUrl = `firefox://open-url?url=${url}`;
  //     window.location.href = firefoxUrl;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const iosSearchUrl = `x-web-search://?${url}`;
  //     window.location.href = iosSearchUrl;
 
  //     return false;
  //   }

  //   // Android detection
  //   if (/android/i.test(userAgent)) {
  //     const androidIntent = `intent://${url.replace("https://", "")}#Intent;scheme=https;package=com.android.chrome;end;`;
  //     window.location.href = androidIntent;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const chromeUrl = `googlechrome://navigate?url=${url}`;
  //     window.location.href = chromeUrl;
  //     await new Promise((r) => setTimeout(r, 1000));
  //     const firefoxUrl = `firefox://open-url?url=${url}`;
  //     window.location.href = firefoxUrl;
 
  //     return false;
  //   }
 
  //    return;
  // };

  // useEffect(() => {
 
  //   redirectToDeviceBrowser();
  // }, []);
  return (
    <main className={classes.body}>
      <Helmet title="Trang Sức Cao Cấp PNJ | Cty Cổ Phần Vàng Bạc Đá Quý Phú Nhuận" defer={false} />
      <HeaderComponent />
      
      {initLoading && !isExpired && <LoadingComponent />}
      {!initLoading && !soNum && !isExpired && <NotFoundComponent onClickSubmit={initValidate} />}
      {soNum && phoneNum && !initLoading && !isValidate && !isSave && !isExpired && (
        <NotFoundComponent onClickSubmit={initValidate} />
      )}

      {/* {soNum && !phoneNum && !isValidate && !isSave && (
        <PhoneCodeInputComponent setPhoneNumber={submitPhoneNumber} />
      )} */}

{!isSave && !isExpired && isValidate && (
  <div className="body">
  {rating < 0 && 
  <div className="flex justify-end max-w-[900px] mx-auto mt-[10px] px-[10px]">
  <Header/>
  </div>
  }  
    <RatingV2Component rating={rating} selectRating={selectRating} />
    <QuestionV2Component
      submitQuestion={submitQuestion}
      questionsData={questions}
      rating={rating}
    /> 
 </div>
)}
{isSave && !isExpired && (
    <ThankYouComponent 
    rating={rating} 
    soNumState={soNumState} 
    phoneNumState={phoneNumState} 
    handleReFeedBack={handleReFeedBack}
   />
)}  

      {!initLoading && isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {isExpired && <ExpiredLink />}

      <Snackbar open={!!errorMessage} autoHideDuration={1000} message={errorMessage}></Snackbar>
    </main>
  );
};

export default IndexPage;
