import { Grid  } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../style";
import logoPnj from "../../images/assets/logo.png";
import {useTranslation} from 'gatsby-plugin-react-i18next';



// markup
export const ExpiredLink = ( ) => {
  const classes = useStyles();
  const { t } = useTranslation();
   return (
    <>
      <div
        className={"bg-[url('https://cdn.pnj.io/images/2024/rebuild/backgroundRatingDesktop.png?1732695460944')] w-full h-[calc(100vh_-_49px)] bg-cover bg-center mx-auto flex items-center"}
        
      >
           <div className="flex flex-col items-center justify-center sm:gap-[48px] gap-[26px] px-[10px] max-w-[1014px] mx-auto">
                  
            <div>
            <div className="thank-title sm:text-[30px] text-[18px] font-bold text-center">
            {t('expired time to evaluate title')}
          </div>
        
           
          <div className="text-[#003468] sm:text-[30px] text-[12px] text-center font-medium" >
            {t('expired time to evaluate description')}
          </div>
            </div>
        
       
    
          
          <div className="sm:text-[30px] text-[14px] bg-[#FFFFFF] shadow-buttonShadow text-[#D49917] text-center py-[12px] rounded-[5px] sm:w-[440px] w-[195px] px- font-bold ">
            <a href="tel:1800545457">{t('call button')}</a>
          </div>
 
           
           </div>

       
       </div>
    </>
  );
};
